import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'
import querystring from 'querystring'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @returns {Promise}
 */
export function getSessions (args, page, perPage) {
  const additional = querystring.stringify(args)
  let params = `action=filters&page=${page}&per_page=${perPage}&order_by=created_at+desc`
  if (additional !== '') params = `${params}&${additional}`

  return axios.get(API('sessions', params), {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * @returns {Promise}
 */
export function getSessionsTotal (args) {
  const additional = querystring.stringify(args)
  let params = 'action=filtered-total'
  if (additional !== '') params = `${params}&${additional}`

  return axios.get(API('sessions', params), {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * @returns {Promise}
 */
export function getSession (sessionID) {
  return axios.get(API('sessions', `id=${sessionID}`), {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * @returns {Promise}
 */
export function getPatientLastSessions (patientIds, perPage) {
  let ids = patientIds
  if (Array.isArray(patientIds)) {
    ids = patientIds.join(',')
  }
  const params = `action=patient-last-sessions&per_page=${perPage}&order_by=created_at+desc&patient_ids=${ids}`

  return axios.get(API('sessions', params), {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * @returns {Promise}
 */
export function getSessionsParameters (args) {
  const additional = querystring.stringify(args)
  let params = 'action=sessions-parameters'
  if (additional !== '') params = `${params}&${additional}`

  return axios.get(API('sessions', params), {
    headers: {
      Authorization: getJwtString()
    }
  })
}
