import React, { useState, useContext } from 'react'
import { Pie } from 'react-chartjs-2'
import { navigate, Link } from 'gatsby'
import moment from 'moment-timezone'

import './dashboard.scss'

import { Context } from '../Context'
import jwt, { isBrowser, rolesHasOneOf } from '../components/Auth'
import Layout from '../components/Layout'
import usePatients from '../hooks/patients'
import useSessions from '../hooks/sessions'
import { StartCallHeader } from '../components/StartCallHeader'

const DashboardPage = () => {
  const { isAuth } = useContext(Context)

  if (!isAuth) {
    if (isBrowser()) {
      window.location.href = '/'
    }
    return (
      <Layout withoutHeader className='page-dashboard'>
        Cargando...
      </Layout>
    )
  }

  // receptor (id=3); director de receptores (id=5); aseguradora (id=6); sanidad militar (id=7)
  if (rolesHasOneOf([3, 5, 6, 7])) {
    if (isBrowser()) {
      navigate('/profile/')
    }
    return (
      <Layout withoutHeader className='page-dashboard'>
        Cargando...
      </Layout>
    )
  }

  const [date1, setDate1] = useState(
    moment()
      .subtract(moment.duration(3, 'months'))
      .format('YYYY-MM-DD')
  )
  const [date2, setDate2] = useState(
    moment()
      .add(moment.duration(1, 'days'))
      .format('YYYY-MM-DD')
  )

  // casos recientes
  const recents = usePatients(
    [],
    `action=recent&author=${jwt().payload.user_id}`
  )

  // sesiones por completar
  const drafts = useSessions(`action=draft&author=${jwt().payload.user_id}`)

  // torta de borrador y guardado
  const saveAndDrafts = useSessions(
    `action=save-and-draft&author=${
      jwt().payload.user_id
    }&date1=${date1}&date2=${date2}`,
    [date1, date2]
  )

  // casos por fase
  const phases = usePatients(
    {},
    `action=phases&author=${
      jwt().payload.user_id
    }&date1=${date1}&date2=${date2}`,
    [date1, date2]
  )

  return (
    <Layout className='page-dashboard'>
      <div className='container'>
        <StartCallHeader />

        <div className='content'>
          <div className='filters-container'>
            <Link to='/filters/' style={{ color: 'white' }}>
              FILTROS
            </Link>
          </div>

          <div className='tables'>
            <div className='active-cases'>
              <div className='title'>MIS ÚLTIMOS 10 CASOS ACTIVOS</div>
              <table
                className='table'
                style={{
                  borderRight: '1px solid var(--color4)'
                }}
              >
                <tbody>
                  <tr>
                    <th>FECHA</th>
                    <th>NOMBRE</th>
                    <th style={{ paddingRight: '1rem' }}>FASE</th>
                  </tr>
                  {recents.map((row, index) => (
                    <tr key={index}>
                      <td>
                        {moment
                          .utc(row.date)
                          .tz('America/Bogota')
                          .format('YYYY-MM-DD HH:mm:ss')}
                      </td>
                      <td>
                        <Link
                          to={`/session/?patient_id=${row.id}&session_id=${row.session_id}`}
                          style={{ color: 'var(--color0)' }}
                        >
                          {row.name} {row.lastname}
                        </Link>
                      </td>
                      <td>{row.phase}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className='draffs'>
              <div className='title'>SESIONES POR COMPLETAR</div>
              <table
                className='table'
                style={{
                  borderRight: '1px solid var(--color4)'
                }}
              >
                <tbody>
                  <tr>
                    <th style={{ paddingRight: '1rem' }}>NOMBRE</th>
                  </tr>

                  {drafts.map(row => (
                    <tr key={row.id}>
                      <td>
                        <Link
                          to={`/session/?patient_id=${row.patient_id}&session_id=${row.id}`}
                          style={{ color: 'var(--color0)' }}
                        >
                          {row.name} {row.lastname}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='pie-container'>
            <div className='container-dates'>
              <label>
                Desde:
                <input
                  type='date'
                  placeholder='YYYY-MM-DD'
                  value={date1}
                  onChange={e => setDate1(e.target.value)}
                  className='textfield'
                />
              </label>

              <label>
                Hasta:
                <input
                  type='date'
                  placeholder='YYYY-MM-DD'
                  value={date2}
                  onChange={e => setDate2(e.target.value)}
                  className='textfield'
                />
              </label>
            </div>

            <Pie
              width={100}
              height={100}
              responsive
              data={{
                datasets: [
                  {
                    data: [saveAndDrafts.saved, saveAndDrafts.drafts],
                    backgroundColor: [
                      'rgba(255, 255, 255, 0.6)',
                      'rgba(255, 255, 255, 0.2)'
                    ]
                  }
                ],

                labels: ['Guardadas', 'Borrador']
              }}
            />
          </div>

          <div className='cases-for-fase'>
            {phases.sin_fase && phases.sin_fase.casos ? (
              <div
                className='fase fase-0'
                style={{
                  width: `${phases.sin_fase ? phases.sin_fase.porcentaje : 0}%`
                }}
              >
                <div className='info'>
                  <div>SIN FASE</div>
                  <div>{phases.sin_fase ? phases.sin_fase.porcentaje : 0}%</div>
                  <div>
                    {phases.sin_fase ? `${phases.sin_fase.casos}` : 0} casos
                  </div>
                </div>
              </div>
            ) : null}

            {phases.seguimiento && phases.seguimiento.casos ? (
              <div
                className='fase fase-1'
                style={{
                  width: `${
                    phases.seguimiento ? phases.seguimiento.porcentaje : 0
                  }%`
                }}
              >
                <div className='info'>
                  <div>SEGUIMIENTO</div>
                  <div>
                    {phases.seguimiento ? phases.seguimiento.porcentaje : 0}%
                  </div>
                  <div>
                    {phases.seguimiento ? phases.seguimiento.casos : 0} casos
                  </div>
                </div>
              </div>
            ) : null}

            {phases.tratamiento && phases.tratamiento.casos ? (
              <div
                className='fase fase-2'
                style={{
                  width: `${
                    phases.tratamiento ? phases.tratamiento.porcentaje : 0
                  }%`
                }}
              >
                <div className='info'>
                  <div>TRATAMIENTO</div>
                  <div>
                    {phases.tratamiento ? phases.tratamiento.porcentaje : 0}%
                  </div>
                  <div>
                    {phases.tratamiento ? phases.tratamiento.casos : 0} casos
                  </div>
                </div>
              </div>
            ) : null}

            {phases['evaluación'] && phases['evaluación'].casos ? (
              <div
                className='fase fase-3'
                style={{
                  width: `${
                    phases['evaluación'] ? phases['evaluación'].porcentaje : 0
                  }%`
                }}
              >
                <div className='info'>
                  <div>EVALUACIÓN</div>
                  <div>
                    {phases['evaluación'] ? phases['evaluación'].porcentaje : 0}
                    %
                  </div>
                  <div>
                    {phases['evaluación'] ? phases['evaluación'].casos : 0}{' '}
                    casos
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DashboardPage
